import React from 'react'
import { navigate } from 'gatsby-link'
import { Link } from 'gatsby'
import Layout from '../../components/Layout'
import Hero from '../../components/Hero'
import SEO from "../../components/seo"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => alert(error))
  }

  render() {
    return (
      <Layout>
        <SEO title="Skráning til að taka þátt í Heima er best"
        pathname="/sign-up/"
          />

         <Hero 
         headerText="Taktu þátt" 
         subHeaderText="Öll fyrirtæki í ferðaþjónustu geta tekið þátt í verkefninu með okkur"
         heroBanner="https://images.squarespace-cdn.com/content/v1/52a74d9ae4b0253945d2aee9/1390513380961-PTHFXE5U2S1FJSLPXUSD/ke17ZwdGBToddI8pDm48kA2MStDQoZ8TsVhOdZz9ancUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYxCRW4BPu10St3TBAUQYVKc02ZN02e9uXhS_VCIvN0BPHpXNLjw_J6OzsHaJFwWx58AMuMC3AT9ww3KmldDNe7v/tumblr_mh1iruZWLf1rkz363o1_1280.jpg?format=1500w" /> 

        <section className="section content column is-10 is-offset-1">
          <div className="columns">
            <div className="column is-6">
              <h2>Það kostar ekkert að taka þátt</h2>
              <p className="container intro">Við hvetjum öll fyrirtæki sem hafa áhuga á því að taka þátt að skrá sig til leiks.</p>
              <p className="container intro">Þau fyrirtæki sem eru í Bókun er hvött til að senda einnig samningsbeiðni á Heima er best og fylgja <Link to="/setup/">uppsetningarleiðbeiningum</Link>.</p>

            </div>
            <div className="column is-6">
              <h2>Skráningarform</h2>
              <p>Fyrir þau fyrirtæki sem vilja taka þátt í þessu verkefni</p>

            <form
              name="sign-up"
              method="post"
              action="/sign-up/thanks/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={this.handleSubmit}
            >
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="sign-up" />
              <div hidden>
                <label>
                  Don’t fill this out:{' '}
                  <input name="bot-field" onChange={this.handleChange} />
                </label>
              </div>
              <div className="field">
                <label className="label" htmlFor={'name'}>
                  Nafn*
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={'text'}
                    name={'name'}
                    onChange={this.handleChange}
                    id={'name'}
                    required={true}
                  />
                </div>
              </div>
              
              <div className="field">
                <label className="label" htmlFor={'email'}>
                  Netfang*
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={'email'}
                    name={'email'}
                    onChange={this.handleChange}
                    id={'email'}
                    required={true}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label" htmlFor={'website'}>
                  Vefslóð
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={'text'}
                    name={'website'}
                    onChange={this.handleChange}
                    id={'website'}
                    required={false}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label" htmlFor={'company'}>
                  Nafn fyrirtækis
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={'text'}
                    name={'company'}
                    onChange={this.handleChange}
                    id={'company'}
                    required={false}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label" htmlFor={'phone'}>
                  Símanúmer
                </label>
                <div className="control">
                  <input
                    className="input"
                    type={'text'}
                    name={'phone'}
                    onChange={this.handleChange}
                    id={'phone'}
                    required={false}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label" htmlFor={'plan'}>
                  Flokkur fyrirtækis
                </label>
                <div className="select">
                  <select
                    className="select"
                    type="radio"
                    name={'plan'}
                    onChange={this.handleChange}
                    id={'plan'}
                    required={false}                
                  >
                    <option value=""> - vinsamlegast veldu -</option>
                    <option value="tours">Dagsferðir</option>
                    <option value="activities">Upplifun</option>
                    <option value="accommodation">Gisting</option>
                    <option value="Other">Annað</option>
                  </select>
                </div>
              </div>
              <div className="field">
                <button className="button is-primary" type="submit">
                  Senda
                </button>
              </div>
            </form>   

            </div>
          </div>
             
              
              
              <div className="columns is-10 is-offset-1 margin-above">
                
                <div className="column is-6">

                   
                
                </div>   
              </div>   
        </section>
      </Layout>
    )
  }
}
